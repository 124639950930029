import { Injectable } from '@angular/core';
import { gsap, Power2, Elastic } from 'gsap/all';

@Injectable({
  providedIn: 'root'
})
export class AnimationsService {

  public fadeFrom(element , duration, alpha, delay) {
    gsap.from(element, {duration: duration, opacity: alpha, ease: Power2 , delay: delay});
  }
}
