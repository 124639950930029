import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { BusComponent } from "./bus/bus.component";

import { FormsModule } from "@angular/forms";
import { NgZorroAntdModule, NZ_I18N, es_ES } from "ng-zorro-antd";
import { NzRadioModule } from "ng-zorro-antd/radio";
import { CommonModule } from "@angular/common";

const routes: Routes = [
  {
    // tslint:disable-next-line:max-line-length
    path: "trip/:transactionId", // :codigoLinea/:fechaSalidaServicio/:inicio/:fin/:agenciaDestino/:horaSalidaServicio/:fechaSalidaTramo/:horaSalidaTramo/:fechaLlegadaTramo/:horaLlegadaTramo/:numeroAsiento/:usuarioTransaccion/:numeroViaje/:cantidadAsientos/:tipoViaje/:numeroCabina/:valorTarifa/:valorDescuento/:codigoIdaRegreso/:numeroTransaccion',
    component: BusComponent,
  },
];

@NgModule({
  declarations: [BusComponent],
  imports: [
    CommonModule,
    NgZorroAntdModule,
    FormsModule,
    NzRadioModule,
    RouterModule.forRoot(routes),
  ],
  providers: [{ provide: NZ_I18N, useValue: es_ES }],
  exports: [RouterModule],
})
export class AppRoutingModule {}
