import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import {
  AngularFirestoreCollection,
  AngularFirestore,
} from "@angular/fire/firestore";

@Injectable({
  providedIn: "root",
})
export class BusService {
  //private busQueryURL = 'https://smartpassenger.chatbotics.cl:3000/webhooks';
  private busQueryURL = "https://f582-190-22-14-204.ngrok.io/webhooks";

  constructor(private http: HttpClient, private db: AngularFirestore) {}

  getAsientos(transactionId) {
    return new Observable((observer) => {
      this.http.get(this.busQueryURL + "/bus/" + transactionId).subscribe(
        (res: any) => {
          console.log(res);
          observer.next(res);
        },
        (error) => {}
      );
    });
  }

  public getTransaction(transactionId: string) {
    return this.db.collection("transactions").doc(transactionId).get();
  }
}
