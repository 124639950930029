import {
  Component,
  OnInit,
  AfterViewInit,
  ElementRef,
  Inject,
  Renderer2,
  HostListener,
} from "@angular/core";
import { BusService } from "../services/bus.service";
import { AnimationsService } from "../services/animations.service";
import { ActivatedRoute } from "@angular/router";
import { DOCUMENT } from "@angular/common";

import sampleData from "../services/bus.json";
declare global {
  interface Window {
    extAsyncInit: any;
  }
}

@Component({
  selector: "app-bus",
  templateUrl: "./bus.component.html",
  styleUrls: ["./bus.component.css"],
})
export class BusComponent implements OnInit, AfterViewInit {
  title = "smartPassengerBus";
  urlOcupado = "https://m.turbus.cl/documents/20195/20647/asiento-escogido.png";
  urlSeleccionado =
    "https://m.turbus.cl/documents/20195/20647/asiento-seleccionado.png";
  urlDisponible =
    "https://m.turbus.cl/documents/20195/20647/asiento-disponible.png";

  baseServerUrl = "https://f582-190-22-14-204.ngrok.io";

  currentFloor;
  floorTitle = "Primer Piso";
  floorSelected = "1";
  asientosSeleccionados = [];
  cantAsientos = 3;
  params = {};
  loading = false;

  exampleData = {
    codigoLinea: 1,
    fechaSalidaServicio: "29/03/2021",
    mnemotecnicoCiudadOrigenServicio: "stgo",
    mnemotecnicoCiudadDestinoServicio: "vina",
    codigoAgenciaDestinoServicio: "a",
    horaSalidaServicio: 830,
    fechaSalidaTramo: "29/03/2021",
    horaSalidaTramo: 830,
    fechaLlegadaTramo: "29/03/2021",
    horaLlegadaTramo: 1020,
    numeroAsiento: 0,
    usuarioTransaccion: "web1",
    numeroViaje: 0,
    cantidadAsientos: 1,
    tipoViaje: "N",
    numeroCabina: 1,
    valorTarifa: 3300,
    valorDescuento: 300,
    codigoIdaRegreso: 0,
    numeroTransaccion: 18614836543,
  };

  exData;
  @HostListener("window:extAsyncInit", ["$event"]) onFbEvent($event) {
    console.log("called");
  }
  constructor(
    private busServices: BusService,
    private animationService: AnimationsService,
    private activatedRoute: ActivatedRoute,
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private el: ElementRef
  ) {
    this.loading = true;

    const transactionId = this.activatedRoute.snapshot.params.transactionId;
    this.loadData(transactionId);

    /*
    this.activatedRoute.paramMap.subscribe(data => {
      this.params = (data as any).params;
      console.log(this.params);
    });
    */
  }

  onFloorChange(event) {
    this.currentFloor = this.getGrid(this.floorSelected);

    if (this.floorSelected === "1") {
      this.floorTitle = "Primer Piso";
    } else {
      this.floorTitle = "Segundo Piso";
    }
  }
  ngAfterViewInit() {
    const s = this.renderer2.createElement("script");
    s.type = "text/javascript";
    s.innerHTML =
      "(function(d, s, id){" +
      "var js, fjs = d.getElementsByTagName(s)[0];" +
      "if (d.getElementById(id)) {return;}" +
      "js = d.createElement(s); js.id = id;" +
      'js.src = "//connect.facebook.net/en_US/messenger.Extensions.js";' +
      "fjs.parentNode.insertBefore(js, fjs);" +
      '}(document, "script", "Messenger"));';

    this.renderer2.appendChild(this.el.nativeElement, s);
    window.extAsyncInit = function () {
      console.log("Cargo el boton de pagar");
    };
  }

  loadData(transactionId) {
    this.busServices.getAsientos(transactionId).subscribe((data: any) => {
      this.exData = data;
      //this.cantAsientos = Number(this.exData.length);

      this.currentFloor = this.getGrid(this.floorSelected);
      const seats = ".bus-seat";
      this.animationService.fadeFrom(seats, 1, 0, 10);
      this.loading = false;
    });
  }

  getGrid(floorNumber) {
    const floor = this.exData.filter((seat) => seat.numeroPiso == floorNumber);
    const items = floor.reduce(function (r, a, i) {
      r[a.plantilla.posicionFilaAsiento] =
        r[a.plantilla.posicionFilaAsiento] || [];
      a.relleno = false;
      r[a.plantilla.posicionFilaAsiento].push(a);
      if (a.plantilla.indicadorPosicionAsiento === 2) {
        r[a.plantilla.posicionFilaAsiento].push({ relleno: true });
      }
      return r;
    }, Object.create(null));
    const arrayFila = Object.keys(items);
    const response = [];
    const finalResponse = [];

    for (const prop of arrayFila) {
      response.push(items[prop]);
    }

    response.forEach((fila) => {
      if (fila.length < 5) {
        for (let index = 0; index < 6 - fila.length; index++) {
          fila.push({ relleno: true });
        }
      }
      finalResponse.push(fila);
    });

    return finalResponse;
  }

  selectSeat(event, asiento) {
    console.log("Seleccionando asiento", this.asientosSeleccionados);
    if (
      asiento.codigoEstadoAsiento === 6 ||
      asiento.codigoEstadoAsiento === 1 ||
      asiento.codigoEstadoAsiento === 2
    ) {
      return;
    } else {
      const canAdd = this.asientosSeleccionados.length === this.cantAsientos;

      const exist = this.asientosSeleccionados.find(
        (a) => a.numeroAsiento === asiento.numeroAsiento
      );

      if (exist) {
        this.asientosSeleccionados = this.asientosSeleccionados.filter(
          (a) => a.numeroAsiento !== exist.numeroAsiento
        );
      } else if (!canAdd) {
        this.asientosSeleccionados.push(asiento);
      } else if (canAdd) {
        this.asientosSeleccionados.shift();
        this.asientosSeleccionados.push(asiento);
      }
    }
  }

  isSeatSelected(asiento) {
    return this.asientosSeleccionados.find(
      (a) => a.numeroAsiento === asiento.numeroAsiento
    );
  }

  ngOnInit() {}

  showPay() {
    return this.asientosSeleccionados.length >= this.cantAsientos;
  }
}
